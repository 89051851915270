import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Checkbox from '../common/checkbox'

const ContactConsent = (props) => {
  const { push } = useHistory()

  const onConsent = () => {
    props.setConsentGiven()
    push('/identity/idUpload')
  }

  const [consent, setConsent] = useState(false)
  return (
    <div className='p-4 xs:p-6 data-collection-consent'>
      <h4 className='text-center'>In order to comply with various laws we need to verify the following:</h4>
      <br />
      <div>
        <ul className='px-3'>
          <li>
            <h4>-&nbsp;&nbsp;Name, Address, Date of Birth,</h4>
          </li>
          <li>
            <h4>-&nbsp;&nbsp;Drivers license and/or other identifying documents.</h4>
          </li>
        </ul>
      </div>
      <br />
      <br />
      <Checkbox
        checked={consent}
        onChange={setConsent}
        name='consent'
        label='I agree to provide this information truthfully'
      />
      <br />
      <br />

      <button className='btn-primary w-full' disabled={!consent} onClick={onConsent}>
        Continue
      </button>
    </div>
  )
}

export default ContactConsent
