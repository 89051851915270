import React from 'react'
import { BASIS_ID_FILE_UPLOAD_URL } from '../common/endpoints'
import { useHistory } from 'react-router-dom'
import ImageUpload from '../common/imageUpload'
import { useNotification } from '../common/notification'

const UtilityUpload = (props) => {
  const { push } = useHistory()

  const handleContinue = () => {
    push('/verification/status')
  }

  const { simpleNotification } = useNotification()

  return (
    <div className='p-4 xs:p-6 id-image-upload'>
      <h3 className='text-center'>Upload proof of address.</h3>
      <br />
      <h5 className='text-center'>Make sure that the information is easily readable.</h5>
      <br />
      <div className=''>
        <ImageUpload
          file={props.user.raw_proofOfAddressPhoto}
          title=''
          onImageUploadFail={() => {
            simpleNotification('File upload failed.', {duration: 3, type: 'error'})
          }}
          onImageUploadSuccess={(raw, path) => {
            props.setProofOfAddressPhoto(path, raw)
          }}
          uploadURL={BASIS_ID_FILE_UPLOAD_URL}
        />
      </div>
      <br />
      <br />
      <button
        className='btn-primary w-full'
        disabled={!props.user.proofOfAddressPhoto || props.user.proofOfAddressPhoto === ''}
        onClick={handleContinue}
      >
        Continue
      </button>
    </div>
  )
}

export default UtilityUpload
