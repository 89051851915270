import React, { useState, useEffect } from 'react'
import { BASIS_ID_FILE_UPLOAD_URL, BASIS_ID_EXTRACT_MRZ_URL } from '../common/endpoints'
import { useHistory } from 'react-router-dom'
import ImageUpload from '../common/imageUpload'
import { useNotification } from '../common/notification'
import { Line } from 'rc-progress'
import { stringChange } from '../common/utils'

const IdentityImageUpload = (props) => {
  const { push } = useHistory()
  const { simpleNotification } = useNotification()
  const [processingMRZForSideOne, setProcessingMRZForSideOne] = useState(false)
  const [processingMRZForSideTwo, setProcessingMRZForSideTwo] = useState(false)
  const [processingPercentIndex, setProcessingPercentIndex] = useState(0)
  const percentValues = [13, 18, 27, 35, 49, 55, 67, 80]
  const [currentProcessedData, setCurrentProcessedData] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [documentType, setDocumentType] = useState(props.user.profile.documentType || 'passport')

  const handleContinue = () => {
    setSubmitted(true)
  }

  const extractMRZ = (filepath, setProcessingFlag, side) => {
    setProcessingFlag(true)
    fetch(BASIS_ID_EXTRACT_MRZ_URL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ filepath: filepath }),
    })
      .then((res) => res.json())
      .then((response) => {
        // TODO handle processed info
        if (response.success) {
          setCurrentProcessedData({
            ...props.user.profile,
            profileBeforeOCR: props.user.profile.profileBeforeOCR | props.user.profile,
            lastName: response.lastName,
            firstName: response.firstName,
            dateOfBirth: response.dateOfBirth,
            gender: response.gender === 'notspecified' ? undefined : response.gender,
            documentType: documentType,
            identityNumber: response.idNumber,
            identityDocumentExpiryDate: response.expirationDate,
            usedOCRonSide: side,
          })
        }
        setProcessingFlag(false)
      })
      .catch((e) => {
        setProcessingFlag(false)
      })
  }

  const incrementPercent = () => {
    if (processingPercentIndex < 7) {
      setProcessingPercentIndex((i) => i + 1)
    }
  }

  useEffect(() => {
    var timeoutIndex = -1
    if (submitted) {
      timeoutIndex = setTimeout(incrementPercent, 1200)
    }
    return () => clearTimeout(timeoutIndex)
  }, [submitted, processingPercentIndex])

  useEffect(() => {
    if (submitted) {
      if (!processingMRZForSideOne && !processingMRZForSideTwo) {
        if (currentProcessedData) {
          props.updateProfile(currentProcessedData)
        } else {
          // in case there was already an old OCR detail present
          props.updateProfile({ ...props.user.profile.profileBeforeOCR,  documentType: documentType })
        }
        push('/identity/profile')
      }
    }
  }, [submitted, processingMRZForSideOne, processingMRZForSideTwo])

  return (
    <div className='p-4 xs:p-6 id-image-upload'>
      <h3 className='text-center'>Please upload your identification document.</h3>
      <br />
      <h5 className='text-center font-bold'>
        Make sure that the document isn't cropped and that it is clear and easily readable.
      </h5>
      <br />
      <label htmlFor='documentType'>Document type *</label>
      <select id='documentType' name='documentType' value={documentType} onChange={stringChange(setDocumentType)}>
        <option disabled={true} value='' hidden></option>
        <option value='passport'>Passport</option>
        <option value='iddocument'>Identity document</option>
        <option value='driverslicense'>Driver's license</option>
      </select>

      <div className='flex justify-around'>
        <ImageUpload
          file={props.user.raw_IDPhotoSideOne}
          title='Side One'
          onImageUploadFail={() => {
            simpleNotification('File upload failed.', { duration: 5, type: 'error' })
          }}
          onImageUploadSuccess={(raw, path) => {
            props.setIDPhotos(path, raw, props.user.IDPhotoSideTwo, props.user.raw_IDPhotoSideTwo)
            if (raw) {
              if (documentType !== 'driverslicense') {
                extractMRZ(path, setProcessingMRZForSideOne, 'sideone')
              }
            } else {
              if (currentProcessedData && currentProcessedData.usedOCRonSide === 'sideOne') {
                setCurrentProcessedData(undefined)
              }
              setProcessingMRZForSideOne(false)
            }
          }}
          disableButtons={processingMRZForSideOne}
          uploadURL={BASIS_ID_FILE_UPLOAD_URL}
        />
        {documentType === 'iddocument' && (
          <ImageUpload
            file={props.user.raw_IDPhotoSideTwo}
            title='Side Two'
            onImageUploadFail={() => {
              simpleNotification('File upload failed.', { duration: 5, type: 'error' })
            }}
            onImageUploadSuccess={(raw, path) => {
              props.setIDPhotos(props.user.IDPhotoSideOne, props.user.raw_IDPhotoSideOne, path, raw)
              if (raw) {
                if (documentType !== 'driverslicense') {
                  extractMRZ(path, setProcessingMRZForSideTwo, 'sidetwo')
                }
              } else {
                if (currentProcessedData && currentProcessedData.usedOCRonSide === 'sidetwo') {
                  setCurrentProcessedData(undefined)
                }
                setProcessingMRZForSideTwo(false)
              }
            }}
            disableButtons={processingMRZForSideTwo}
            uploadURL={BASIS_ID_FILE_UPLOAD_URL}
          />
        )}
      </div>
      {submitted && (processingMRZForSideOne || processingMRZForSideTwo) && (
        <>
          <br />
          <p>Extracting information from image...</p>
          <Line percent={percentValues[processingPercentIndex]}></Line>
        </>
      )}
      <br />
      <br />
      <button
        className='btn-primary w-full'
        disabled={
          submitted ||
          !props.user.IDPhotoSideOne ||
          props.user.IDPhotoSideOne === '' ||
          (documentType === 'iddocument' && (!props.user.IDPhotoSideTwo || props.user.IDPhotoSideTwo === ''))
        }
        onClick={handleContinue}
      >
        Continue
      </button>
    </div>
  )
}

export default IdentityImageUpload
