import React from 'react'

const Checkbox = ({ label, onChange, checked, name = 'checkbox' }) => {
  return (
    <label className='flex justify-start items-start'>
      <div className='bg-white border rounded border-primary-400 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-primary-500'>
        <input
          id='consentCheckbox'
          type='checkbox'
          className='opacity-0 absolute'
          name={name}
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
        <svg
          className={`${checked ? 'block' : 'hidden'} fill-current w-3 h-3 text-primary-500 pointer-events-none`}
          viewBox='0 0 20 20'
        >
          <path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
        </svg>
      </div>
      <div className='select-none leading-tight'>{label}</div>
    </label>
  )
}

export default Checkbox
