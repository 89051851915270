import React, { useEffect, useState } from 'react'
import { getValidityClass } from '../common/utils'
import { DateTime } from 'luxon'

import './simpleDatePicker.css'

const SimpleDatePicker = ({ label, date, onChange, required = false, validator }) => {
  let luxonDate = DateTime.fromISO(date)
  const [year, setYear] = useState(date ? luxonDate.year : undefined)
  const [month, setMonth] = useState(date ? luxonDate.month : undefined)
  const [day, setDay] = useState(date ? luxonDate.day : undefined)

  let monthRef = React.createRef()
  let dayRef = React.createRef()

  const [isTouched, setTouched] = useState(date !== undefined)
  const [isValid, setValid] = useState(true)

  const numberChange = (handler, stepper, target) => (e) => {
    // call onChange only when valid change occurred
    handler(e.target.value)
    if (stepper === e.target.value.length) {
      target.current?.focus()
      target.current?.select()
    }
  }

  useEffect(() => {
    let date = DateTime.fromFormat('' + year + ' ' + (''+month).padStart(2, '0') + ' ' + (''+day).padStart(2, '0'), 'yyyy LL dd')
    setValid((date.isValid && validator && validator(date)) || !isTouched || (isTouched && !required))
    if (date.isValid && validator && validator(date)) {
      onChange(date.toISODate())
    } else {
      onChange(undefined)
    }
  }, [year, month, day, isTouched])

  return (
    <div className='simple-date-picker'>
      <label className='block'>{label}</label>
      <div className='w-2/5 inline-block relative pr-2'>
        <input
          type='number'
          id='year'
          placeholder='YYYY'
          onChange={numberChange(setYear, 4, monthRef)}
          onBlur={() => setTouched(true)}
          value={year ? year : ''}
          className={getValidityClass(() => isValid)}
        />
        <span className='absolute right-0 inset-y-0 pt-2 text-sm'>/</span>
      </div>
      <div className='w-3/5 inline-block'>
        <div className='w-1/2 inline-block relative pr-1'>
          <input
            ref={monthRef}
            type='number'
            id='month'
            placeholder='MM'
            onChange={numberChange(setMonth, 2, dayRef)}
            onBlur={() => setTouched(true)}
            value={month ? month : ''}
            className={getValidityClass(() => isValid)}
          />
          <span className='absolute inset-y-0 pt-2 text-sm' style={{ right: -4 }}>
            /
          </span>
        </div>
        <div className='w-1/2 inline-block pl-1'>
          <input
            ref={dayRef}
            type='number'
            id='day'
            placeholder='DD'
            onChange={numberChange(setDay)}
            onBlur={() => setTouched(true)}
            value={day ? day : ''}
            className={getValidityClass(() => isValid)}
          />
        </div>
      </div>
    </div>
  )
}

export default SimpleDatePicker
