import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FaArrowLeft from '@meronex/icons/fa/FaArrowLeft'

import logo from '../assets/fxbet-logo.png'

const Header = () => {
  const { goBack } = useHistory()
  let path = useLocation().pathname
  let showBack = path !== '/' && path !== '/contact' && path !== '/verification/status'

  return (
    <div>
      <div className='px-4 pt-4 pb-6 relative'>
        {showBack && (
          <div
            className='absolute left-0 top-0 bottom-0 pt-5 pl-8 pb-4 pr-4 cursor-pointer hover:text-primary-500'
            onClick={() => goBack()}
          >
            <FaArrowLeft />
          </div>
        )}
        <div className='text-center font-semibold'>
          <span className={'p-1' + (path.match(/^\/contact.*/g) ? ' text-primary-500' : '')}>Contact</span>/
          <span className={'p-1' + (path.match(/^\/identity.*/g) ? ' text-primary-500' : '')}>Identity</span>/
          <span className={'p-1' + (path.match(/^\/address.*/g) ? ' text-primary-500' : '')}>Address</span>
        </div>{' '}
      </div>
      <div className='mb-4 flex flex-col items-center'>
        <img src={logo} width='175' alt='FXBet Logo' />
      </div>
    </div>
  )
}

export default Header
