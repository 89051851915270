const useDev = process.env.NODE_ENV === 'development'

const localIP = 'http://localhost:31337'

export const GET_BASIS_ID_SIGNATURE_URL = (useDev ? localIP : '') + '/api/getBasisIDSignature'

export const BASIS_ID_REGISTRATION_URL = (useDev ? localIP : '') + '/api/sendBasisIDData'

export const BASIS_ID_FILE_UPLOAD_URL = (useDev ? localIP : '') + '/api/uploadFile'

export const BASIS_ID_EXTRACT_MRZ_URL = (useDev ? localIP : '') + '/api/extractMRZ'

export const BASISID_PUB_KEY = 'prod-liQCbayexNvRrubkzYtYeuQQYGqkfExL'
