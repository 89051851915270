import React from 'react'
import { countries } from './countries'
import Select from 'rc-select'
import ReactCountryFlag from 'react-country-flag'

import './rc-select.css'

const PhoneCodeSelect = ({ value, onChange, setDirty }) => {
  return (
    <Select
      id='phoneCode'
      showArrow={false}
      showSearch={true}
      optionLabelProp='label'
      dropdownMatchSelectWidth={310}
      filterOption={(input, option) => {
        return ((input, country, phoneCode) =>
          country.indexOf(input) >= 0 || phoneCode.startsWith(input) || phoneCode.startsWith('+' + input))(
          input.toLowerCase(),
          option.children.props.children[2].props.children[0].toLowerCase(),
          option.children.props.children[2].props.children[2].toLowerCase()
        )
      }}
      optionFilterProp='children'
      onChange={onChange}
      onBlur={setDirty}
      value={value}
    >
      {countries.map((v, i) => (
        <Select.Option
          key={i}
          value={v.alpha2code + v.phoneCode}
          label={
            <div className='flex flex-row items-center'>
              <ReactCountryFlag countryCode={v.alpha2code} svg />
              &nbsp;&nbsp;<span>{v.phoneCode}</span>
            </div>
          }
        >
          <div className='flex flex-row items-center'>
            <ReactCountryFlag countryCode={v.alpha2code} svg />
            &nbsp;
            <span>
              {v.name} ({v.phoneCode})
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

export default PhoneCodeSelect
