import React from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { BASISID_PUB_KEY, BASIS_ID_REGISTRATION_URL, GET_BASIS_ID_SIGNATURE_URL } from './common/endpoints'
import Header from './components/header'
import ContactInput from './components/contactInput'
import ContactVerification from './components/contactVerification'
import ContactConsent from './components/contactConsent'
import ProfileInput from './components/identityProfileInput'
import IdentityImageUpload from './components/identityUpload'
import AddressInput from './components/addressInput'
import UtilityUpload from './components/addressUtilityUpload'
import VerificationStatus from './components/verificationStatus'
import { useNotification } from './common/notification'

const loadState = () => {
  //fetch from local storage
  try {
    const serialisedState = localStorage.getItem('state')
    if (serialisedState === null) {
      return undefined
    }
    return JSON.parse(serialisedState)
  } catch (err) {
    return undefined
  }
}

const stateVersion = 2
const saveState = (state) => {
  try {
    state = { ...state, version: stateVersion }
    const serialisedState = JSON.stringify(state)
    localStorage.setItem('state', serialisedState)
  } catch (err) {}
}

const Router = () => {
  const { push } = useHistory()
  const { simpleNotification } = useNotification()
  const loadedState = loadState()
  const newState = { version: stateVersion, verificationStatus: 'unavailable' }

  const [user, setUser] = React.useState(
    loadedState && loadedState.version === stateVersion ? loadedState : newState

    // {
    //   version: 1,
    //   profile: {
    //     phoneNumber: '230-52953562',
    //     email: 'ubeidjamaml@sd.com',
    //     nationality: 'MU',
    //     lastName: 'Jamal',
    //     firstName: 'Ubeid',
    //     dateOfBirth: '1992-02-01',
    //     gender: 'male',
    //     identityNumber: '1234',
    //     identityDocumentExpiryDate: '2021-02-02',
    //     phoneAndEmailVerified: true,
    //     consentGiven: true,
    //     address: {
    //       country: 'MU',
    //       city: 'Port Louis',
    //       streetName: 'Strr',
    //       postalCode: '81418',
    //     },
    //     raw_mobile: '52953562',
    //     raw_phoneCode: 'MU+230',
    //   },
    //   proofOfAddressPhoto: 'sd',
    //   submittedTimestamp: 'Sat 01 Aug 2020 16:16:16',
    //   allResponses: JSON.stringify({
    //     baseCheckResponse: {
    //       api_access_token: 'aat-2e96518d-80a9-4723-b7eb-4ca09368603f',
    //       api_refresh_token: 'art-b0824290-b8e9-4e5b-bee0-87081df88f67',
    //       blockchain_read_token: '',
    //       status: 'ok',
    //       user_hash: 'd7014035-4721-4bad-a4be-fef816256fea',
    //       user_id: 1400056,
    //     },
    //     idFileOneResponse: {
    //       status: 'ok',
    //       file: '/passport/46541cb9-680c-439d-b0f4-61040a238621.png',
    //     },
    //     idFileTwoResponse: {
    //       status: 'ok',
    //       file: '/passport/064b47ba-888e-4957-bfe1-f87c0087c648.png',
    //     },
    //     utilityFileResponse: {
    //       status: 'ok',
    //       file: '/slip/181f9895-094e-4ef3-aac2-2e0237a3604b.jpg',
    //     },
    //   }),
    //   userHash: 'd7014035-4721-4bad-a4be-fef816256fea',
    //   userId: '1400056',
    //   verificationStatus: 'approved',
    //   verificationTimestamp: 'Sat 01 Aug 2020 16:20:16',
    // }
  )

  const resetState = () => {
    setUser(newState)
  }

  const setBasicProfile = (profile) => {
    setUser((u) => ({
      ...u,
      profile: { ...u.profile, ...profile },
      verificationStatus: 'unavailable',
      submittedTimestamp: undefined,
    }))
  }

  const setPhoneAndEmailVerified = () => {
    setUser((u) => (u.profile ? { ...u, profile: { ...u.profile, phoneAndEmailVerified: true } } : u))
  }

  const setConsentGiven = () => {
    setUser((u) => (u.profile ? { ...u, profile: { ...u.profile, consentGiven: true } } : u))
  }

  const updateAddress = (address) => {
    setUser((u) => (u.profile ? { ...u, profile: { ...u.profile, address: address } } : u))
  }

  const updateProfile = (profile) => {
    setUser((u) => (u.profile ? { ...u, profile: profile } : { ...u, profile: profile }))
  }

  const setIDPhotos = (sideOne, sideOneRaw, sideTwo, sideTwoRaw) => {
    setUser((u) => ({
      ...u,
      IDPhotoSideOne: sideOne,
      IDPhotoSideTwo: sideTwo,
      raw_IDPhotoSideOne: sideOneRaw,
      raw_IDPhotoSideTwo: sideTwoRaw,
    }))
  }

  const setProofOfAddressPhoto = (proofPhoto, proofPhotoRaw) => {
    setUser((u) => ({
      ...u,
      verificationStatus: 'unavailable',
      proofOfAddressPhoto: proofPhoto,
      raw_proofOfAddressPhoto: proofPhotoRaw,
    }))
  }

  const pollBasisIDStatus = async (userHash) => {
    var signatureResponse = await fetch(GET_BASIS_ID_SIGNATURE_URL, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userHash: userHash,
      }),
    }).then(function (response) {
      return response.json()
    })
    if (signatureResponse.success) {
      const checkStatus = async () => {
        let profile = await fetch(
          'https://api.basisid.com/users/info/' + userHash + '/' + BASISID_PUB_KEY + '/' + signatureResponse.signature
        ).then((res) => res.json())

        if (profile && (profile.user_status === 11 || profile.user_status === 10 || profile.user_status === 3)) {
          setUser((u) =>
            u
              ? {
                  ...u,
                  verificationStatus: profile.user_status === 10 ? 'approved' : profile.user_status === 11 ? 'rejected' : 'autocheck_failed',
                  verificationTimestamp: DateTime.local().toFormat('cccc dd LLL yyyy HH:mm:ss'),
                }
              : u
          )
          if (profile.user_status === 11) {
            simpleNotification('Verfication failed. BasisID did not accept your request.', {
              duration: 5,
              type: 'error',
            })
          } else if (profile.user_status === 3) {
            simpleNotification('Auto-verification failed.', {
              duration: 5,
              type: 'warning',
            })
          } else {
            simpleNotification('Verfication successful. BasisID approved your request.', {
              duration: 5,
              type: 'success',
            })
          }
        } else {
          setTimeout(checkStatus, 30000)
        }
      }
      checkStatus()
    }
  }

  const submitToBasisID = async () => {
    if (
      user.profile &&
      user.profile.address &&
      user.profile.dateOfBirth &&
      user.IDPhotoSideOne &&
      user.proofOfAddressPhoto
    ) {
      push('/verification/status')
      let request = {
        key: BASISID_PUB_KEY,
        first_name: user.profile.firstName,
        last_name: user.profile.lastName,
        email: user.profile.email,
        phone: user.profile.phoneNumber,
        gender: user.profile.gender === 'male' ? 0 : user.profile.gender === 'female' ? 1 : 2,
        birthday_day: user.profile.dateOfBirth.substring(8, 10),
        birthday_month: user.profile.dateOfBirth.substring(5, 7),
        birthday_year: user.profile.dateOfBirth.substring(0, 4),
        country_nationality: user.profile.nationality,
        country_residence: user.profile.address.country,
        city: user.profile.address.city,
        address: user.profile.address.streetName,
        zip: user.profile.address.postalCode,
      }
      if (user.profile.middleNames) {
        request['middle_name'] = user.profile.middleNames
      }
      // console.log('creating form data for', user);
      var response = await fetch(BASIS_ID_REGISTRATION_URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          baseCheckRequest: request,
          idFileOnePath: user.IDPhotoSideOne,
          idFileTwoPath: user.IDPhotoSideTwo,
          utilityPath: user.proofOfAddressPhoto,
        }),
      })
        .then(function (response) {
          return response.json()
        })
        .catch((e) => {
          console.log(e)
        })

      if (response && response.success && response.baseCheckResponse.status === 'ok') {
        setUser((u) => ({
          ...u,
          userHash: response.baseCheckResponse.user_hash,
          userId: response.baseCheckResponse.user_id,
          allResponses: JSON.stringify(response),
          verificationStatus: 'processing',
          submittedTimestamp: DateTime.local().toFormat('cccc dd LLL yyyy HH:mm:ss'),
        }))
        // TODO check if one upload failed
        simpleNotification('Request submitted. Review is in process', { duration: 5, type: 'info' })
        pollBasisIDStatus(response.baseCheckResponse.user_hash)
      } else {
        simpleNotification('Request rejected. Please contact an admin', { duration: 5, type: 'error' })
        setUser((u) => ({
          ...u,
          verificationStatus: 'rejected',
          verificationTimestamp: DateTime.local().toFormat('cccc dd LLL yyyy HH:mm:ss'),
        }))
      }
    } else {
      console.log('Not submitting request to basisid as all info in not available.')
    }
  }

  React.useEffect(() => {
    if (user.verificationStatus === 'unavailable' && user.proofOfAddressPhoto) {
      console.log('submitting to basis id')
      submitToBasisID()
    }
    // eslint-disable-next-line
  }, [user.proofOfAddressPhoto]) // call submit when proofOfAddressPhoto changes (i.e. is set)

  React.useEffect(() => {
    if (user && user.verificationStatus === 'processing' && user.userHash) {
      pollBasisIDStatus(user.userHash)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (user) {
      saveState(user)
    }
  }, [user])

  return (
    <div className='container max-w-screen-xs shadow-3xl h-full overflow-auto'>
      <Header />
      <Switch>
        <Redirect exact from='/' to='/contact' />
        <Route exact path='/contact'>
          <ContactInput setBasicProfile={setBasicProfile} userProfile={user.profile} resetState={resetState} />
        </Route>
        <Route exact path='/contact/contactVerification'>
          {user.profile ? (
            <ContactVerification setPhoneAndEmailVerified={setPhoneAndEmailVerified} />
          ) : (
            <Redirect to='/contact' />
          )}
        </Route>
        <Route exact path='/contact/consent'>
          {user.profile ? <ContactConsent setConsentGiven={setConsentGiven} /> : <Redirect to='/contact' />}
        </Route>
        <Redirect exact from='/identity' to='/identity/idUpload' />
        <Route exact path='/identity/idUpload'>
          {user.profile ? <IdentityImageUpload setIDPhotos={setIDPhotos} user={user} updateProfile={updateProfile}/> : <Redirect to='/contact' />}
        </Route>
        <Route exact path='/identity/profile'>
          {user.profile ? (
            <ProfileInput profile={user.profile} updateProfile={updateProfile} />
          ) : (
            <Redirect to='/contact' />
          )}
        </Route>
        <Route exact path='/address/details'>
          {user.profile ? (
            <AddressInput profile={user.profile} updateAddress={updateAddress} />
          ) : (
            <Redirect to='/contact' />
          )}
        </Route>
        <Route exact path='/address/utilityUpload'>
          {user.profile ? (
            <UtilityUpload setProofOfAddressPhoto={setProofOfAddressPhoto} user={user} />
          ) : (
            <Redirect to='/contact' />
          )}
        </Route>
        <Route exact path='/verification/status'>
          {user.profile && user.proofOfAddressPhoto ? <VerificationStatus user={user} /> : <Redirect to='/contact' />}
        </Route>
        <Redirect from='/' to={'/contact'} />
      </Switch>
    </div>
  )
}
export default Router
