import React from 'react'
import AiFillExclamationCircle from '@meronex/icons/ai/AiFillExclamationCircle'
import AiFillInfoCircle from '@meronex/icons/ai/AiFillInfoCircle'
import AiFillWarning from '@meronex/icons/ai/AiFillWarning'
import AiFillCheckCircle from '@meronex/icons/ai/AiFillCheckCircle'

export const NotificationContext = React.createContext(null)

const Info = () => <AiFillInfoCircle className='block mx-auto text-primary-500 text-lg' />
const Error = () => <AiFillExclamationCircle className='block mx-auto text-red-500 text-lg' />
const Warning = () => <AiFillWarning className='block mx-auto text-orange-500 text-lg' />
const Success = () => <AiFillCheckCircle className='block mx-auto text-green-500 text-lg' />

export const useNotification = () => {
  const notification = React.useContext(NotificationContext)
  if (!notification) {
    throw new Error('useNotification must be used within a NotificationContextProvider.')
  }
  return {
    notification: notification,
    simpleNotification: (text, { duration = '3', type = 'info', closable = true }) => {
      notification.notice({
        content: (
          <div className='flex flex-col justify-center'>
            {type === 'warning' ? <Warning /> : type === 'error' ? <Error /> : type === 'success' ? <Success/> : <Info />}
            <div className='text-center pt-2'>{text}</div>
          </div>
        ),
        closable: closable,
        duration: duration,
        onClose() {},
      })
    },
  }
}
