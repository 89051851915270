import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { stringChange, getValidityClass } from '../common/utils'
import SimpleDatePicker from '../common/simpleDatePicker'
import { DateTime } from 'luxon'

const ProfileInput = (props) => {
  const { push } = useHistory()

  const [firstName, setFirstName] = useState(props.profile.firstName)
  const [lastName, setLastName] = useState(props.profile.lastName)
  const [middleNames, setMiddleNames] = useState(props.profile.middleNames)
  const [dateOfBirth, setDateOfBirth] = useState(props.profile.dateOfBirth)
  const [gender, setGender] = useState(props.profile.gender)
  const [documentType, setDocumentType] = useState(props.profile.documentType)
  const [identityNumber, setIdentityNumber] = useState(props.profile.identityNumber)
  const [identityDocExpiryDate, setIdentityDocExpiryDate] = useState(props.profile.identityDocumentExpiryDate)

  const onFinish = () => {
    props.updateProfile({
      ...props.profile,
      lastName: lastName,
      firstName: firstName,
      middleNames: middleNames,
      dateOfBirth: dateOfBirth,
      gender: gender,
      documentType: documentType,
      identityNumber: identityNumber,
      identityDocumentExpiryDate: identityDocExpiryDate,
    })
    push('/address/details')
  }

  const validateFields = () =>
    lastName &&
    lastName.trim() != '' &&
    firstName &&
    firstName.trim() != '' &&
    dateOfBirth &&
    gender &&
    gender.trim() !== '' &&
    identityNumber &&
    identityNumber != ''

  return (
    <div className='p-4 xs:p-6 profile-input'>
      { props.profile.usedOCR && <p className="mb-2 font-bold">Please verify details below.</p>}
      <label htmlFor='firstName'>First name *</label>
      <input
        id='firstName'
        onChange={stringChange(setFirstName)}
        className={getValidityClass(firstName, true)}
        value={firstName ? firstName : ''}
      />
      <label htmlFor='middleNames'>Middle names</label>
      <input
        id='middleNames'
        onChange={stringChange(setMiddleNames)}
        className={getValidityClass(middleNames)}
        value={middleNames ? middleNames : ''}
      />
      <label htmlFor='lastName'>Last name *</label>
      <input
        id='lastName'
        onChange={stringChange(setLastName)}
        className={getValidityClass(lastName, true)}
        value={lastName ? lastName : ''}
      />
      <SimpleDatePicker
        date={dateOfBirth}
        label='Date of birth'
        required={true}
        onChange={setDateOfBirth}
        validator={(current) => current < DateTime.local().startOf('day')}
      />
      <label htmlFor='gender'>Gender *</label>
      <select id='gender' name='gender' value={gender ? gender : ''} onChange={stringChange(setGender)}>
        <option disabled={true} value='' hidden></option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <label htmlFor='identityNumber'>{`${documentType === 'passport' ? 'Passport' : documentType === 'driverslicence' ? 'License' : 'Identity'} number *`}</label>
      <input
        id='identityNumber'
        onChange={stringChange(setIdentityNumber)}
        className={getValidityClass(identityNumber, true)}
        value={identityNumber ? identityNumber : ''}
      />
      <SimpleDatePicker
        date={identityDocExpiryDate}
        label='Document expiry date'
        required={false}
        onChange={setIdentityDocExpiryDate}
      />
      <br />
      <button className='btn-primary w-full block' onClick={onFinish} disabled={!validateFields()}>
        Continue
      </button>
    </div>
  )
}

export default ProfileInput
