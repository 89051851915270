import React, { useState } from 'react'
import CountryCodeSelect from '../common/countryCodeSelect'
import { stringChange, getValidityClass } from '../common/utils'
import { useHistory } from 'react-router-dom'
import { countries } from '../common/countries'

const AddressInput = (props) => {
  const history = useHistory()
  const [countryCode, setCountryCode] = useState(props.profile.address?.country || props.profile.nationality)
  const [city, setCity] = useState(props.profile.address?.city)
  const [streetName, setStreetName] = useState(props.profile.address?.streetName)
  const [postalCode, setPostalCode] = useState(props.profile.address?.postalCode)

  const getCurrentLocation = () => {
    fetch('https://extreme-ip-lookup.com/json') // Get the IP data
      .then((res) => res.json())
      .then((ip) => {
        if (ip.country) {
          const matchedCountry = countries.find((v) => v.alpha2code === ip.countryCode)
          if (matchedCountry) {
            setCountryCode(matchedCountry.alpha2code)
            setCity(ip.city)
          }
        }
      })
  }

  const handleContinue = () => {
    props.updateAddress({
      country: countryCode,
      city: city,
      streetName: streetName,
      postalCode: postalCode,
    })
    history.push('/address/utilityUpload')
  }

  const validateFields = () =>
    countryCode &&
    countryCode !== '' &&
    city &&
    city !== '' &&
    streetName &&
    streetName !== '' &&
    postalCode &&
    postalCode !== ''

  return (
    <div className='p-4 xs:p-6 address-input'>
      <h3 className="text-center">Residential Address</h3>
      <br />
      <br />
      <label htmlFor='countryCode'>Country of residence *</label>
      <CountryCodeSelect value={countryCode} onChange={setCountryCode} />
      <label htmlFor='city'>City *</label>
      <input id='city' onChange={stringChange(setCity)} className={getValidityClass(city, true)} value={city} />
      <label htmlFor='streetName'>Street name *</label>
      <input
        id='streetName'
        onChange={stringChange(setStreetName)}
        className={getValidityClass(streetName, true)}
        value={streetName}
      />
      <label htmlFor='postalCode'>Postal code *</label>
      <input
        id='postalCode'
        onChange={stringChange(setPostalCode)}
        className={getValidityClass(postalCode, true)}
        value={postalCode}
      />
      <br />
      <button className='btn-outline-primary w-full block' onClick={getCurrentLocation}>
        Use Current Location
      </button>
      <br />
      <button className='btn-primary w-full block' onClick={handleContinue} disabled={!validateFields()}>
        Continue
      </button>
    </div>
  )
}

export default AddressInput
