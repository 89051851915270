import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { stringChange } from '../common/utils'

const ContactVerification = (props) => {
  const { replace } = useHistory()
  const [countdown, setCountdown] = useState(30)

  useEffect(() => {
    let timeout = null
    if (countdown === 0) {
      replace('/signup')
    } else {
      timeout = setTimeout(() => setCountdown(countdown - 1), 60000)
    }
    return timeout !== null
      ? () => {
          clearTimeout(timeout)
        }
      : () => {}
  }, [countdown])

  const onVerify = () => {
    props.setPhoneAndEmailVerified()
    replace('/contact/consent')
  }

  const [emailCode, setEmailCode] = useState()
  const [smsCode, setSmsCode] = useState();

  return (
    <div className='p-4 xs:p-6 contact-verification'>
      <h3 className='text-center'>Check your email inbox and SMS for confirmation codes.</h3>
      <br />
      <h5 className='text-center'>The codes we sent will be valid for {countdown} minutes.</h5>
      <br />
      <br />

      <label htmlFor='emailCode' >Email verification code</label>
      <input id='emailCode' onChange={stringChange(setEmailCode)}/>
      <label htmlFor='phoneCode'>SMS verification code</label>
      <input id='phoneCode' onChange={stringChange(setSmsCode)}/>
      <br/>
      <button className="btn-primary w-full" disabled={ !emailCode || emailCode ==='' || !smsCode || smsCode ===''} onClick={onVerify}>Continue</button>
      <br/>
      <span className="float-right pt-2">Having trouble? <button className="btn-link">Resend code</button></span>
    </div>
  )
}

export default ContactVerification
