import React from 'react'
import AiOutlineCheckCircle from '@meronex/icons/ai/AiOutlineCheckCircle'
import AiOutlineExclamationCircle from '@meronex/icons/ai/AiOutlineExclamationCircle'
import EiSpinner3 from '@meronex/icons/ei/EiSpinner3'
import AiFillWarning from '@meronex/icons/ai/AiFillWarning'

const VerificationStatus = (props) => {
  return (
    <div className='p-4 xs:p-6 signup'>
      <h3 className='text-center'>
        {props.user.verificationStatus === 'processing' || props.user.verificationStatus === 'unavailable' ? (
          <>
            Please wait a moment while we validate your documents.
            <br />
            <br />
            <EiSpinner3 className='text-primary-500 animate-spin mx-auto text-5xl' />
          </>
        ) : props.user.verificationStatus === 'approved' ? (
          `Verification complete`
        ) : (
          `Verification issue`
        )}
      </h3>

      <br />
      <div className='text-center'>
        {props.user.verificationStatus === 'processing' || props.user.verificationStatus === 'unavailable' ? (
          <div>
            This page will refresh once verification is complete. This process can take a few minutes.
            <br />
            <br />
            {props.user.submittedTimestamp ? <>Submitted on {props.user.submittedTimestamp}</> : ''}
            {/* <br /> */}
          </div>
        ) : (
          <div>
            <p className='text-center'>
              {props.user.verificationStatus === 'rejected'
                ? `Unfortunately your application has been rejected during the verification process. If you feel this was an error, please contact support on support@fxbet.co.`
                : props.user.verificationStatus === 'autocheck_failed'
                ? `Unfortunately, auto-verification was declined. We will contact you shortly to collect some more information.`
                : `Congratulations! You have passed the verification process.`}
              <br />
              <br />
              {`Your application has been ${
                props.user.verificationStatus === 'autocheck_failed' ? 'updated' : props.user.verificationStatus
              } on`}
              <br />
              {`${props.user.verificationTimestamp}.`}
              {props.user.verificationStatus === 'approved' ? (
                <AiOutlineCheckCircle className='ml-1 inline-block text-lg mb-1 text-green-500' />
              ) : props.user.verificationStatus === 'autocheck_failed' ? (
                <AiFillWarning className='ml-1 inline-block text-lg mb-1 text-orange-500' />
              ) : (
                <AiOutlineExclamationCircle className='ml-1 inline-block text-lg mb-1 text-red-500' />
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default VerificationStatus
