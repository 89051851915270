import React, { useState } from 'react'
import Webcam from 'react-webcam'

import Upload from 'rc-upload'
import FaPlus from '@meronex/icons/fa/FaPlus'
import AiOutlineDelete from '@meronex/icons/ai/AiOutlineDelete'
import AiOutlineCamera from '@meronex/icons/ai/AiOutlineCamera'
import { Line } from 'rc-progress'

const ImageUpload = ({ title, onImageUploadFail, onImageUploadSuccess, file, uploadURL, disableButtons }) => {
  const webcamRef = React.useRef(null)
  const [showCameraImageCapture, setShowCameraImageCapture] = useState(false)
  const [hasError, setError] = useState(false)
  const [progress, setProgress] = useState(0)

  let handlePreview = async (file) => {
    let base64src = await getBase64(file)
    return base64src
  }

  const handleUpdateResponse = (base64src, filepath, filename) => {
    let parsedFile = {
      url: filepath,
      name: filename,
      preview: base64src,
      extractedMRZData: null,
    }
    onImageUploadSuccess(parsedFile, filepath)
  }

  const capture = React.useCallback(() => {
    setError(false)
    setShowCameraImageCapture(false)
    const base64src = webcamRef.current.getScreenshot()
    const file = dataURLtoFile(base64src)
    fileUpload(file, uploadURL).then((result) => {
      handleUpdateResponse(base64src, result.filepath, file.name)
    })
  }, [webcamRef, handlePreview, onImageUploadSuccess])

  const uploadButton = (
    <div className='h-full w-full flex flex-col justify-center text-primary-500'>
      <FaPlus className='block mx-auto' />
      <div className='text-center'>Upload</div>
    </div>
  )

  return (
    <div className=''>
      {showCameraImageCapture && (
        <div className='absolute bg-white w-full h-full inset-0 flex flex-col justify-center'>
          <h2 className='text-center mb-6'>Take a picture.</h2>
          <div className='relative  container max-w-screen-sm'>
            <div className='absolute inset-0 m-auto webcam-border'></div>
            <Webcam
              className='mx-auto shadow-lg w-full h-full'
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              mirrored={false}
            />
          </div>

          <span className='mx-auto mt-6'>
            <button
              className='btn-outline-primary btn-rounded mr-4 px-4'
              onClick={() => setShowCameraImageCapture(false)}
            >
              Cancel
            </button>
            <button className='btn-primary btn-rounded px-4' onClick={capture} disabled={disableButtons}>
              Capture
            </button>
          </span>
        </div>
      )}
      <label className='block text-center'>{title}</label>
      <Upload
        disabled={disableButtons}
        className={`border-2 ${
          hasError ? 'border-red-500' : 'border-gray-200'
        } h-32 w-32 mx-auto p-2 hover:bg-primary-100 hover:bg-opacity-25 ${file ? '' : 'border-dashed'}`}
        component='div'
        action={uploadURL}
        listType='picture-card'
        onError={() => {
          setError(true)
          onImageUploadFail()
        }}
        onProgress={(e) => {
          if (e.percent) {
            setProgress(e.percent)
          }
        }}
        onStart = {() => {
          onImageUploadSuccess(undefined, '')
        }}
        onSuccess={(result, file) => {
          setError(false)
          handlePreview(file).then((base64src) => {
            handleUpdateResponse(base64src, result.filepath, file.name)
          })
        }}
      >
        {file ? <img className='object-contain w-full h-full' src={file.preview} /> : uploadButton}
        {progress !== 100 && progress !== 0 && (
          <Line percent='10' strokeWidth='4' trailWidth='4' strokeColor='#087AC7' />
        )}
      </Upload>
      <div>
        {file && (
          <button
            className='btn-outline-primary btn-rounded block mx-auto mt-2'
            onClick={() => {
              onImageUploadSuccess(undefined, '')
            }}
            disabled={disableButtons}
          >
            <AiOutlineDelete />
          </button>
        )}
        {!file && (
          <button
            className='btn-primary btn-rounded block mx-auto mt-2'
            onClick={() => {
              setShowCameraImageCapture(true)
            }}
            disabled={disableButtons}
          >
            <AiOutlineCamera />
          </button>
        )}
      </div>
    </div>
  )
}

export default ImageUpload

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const dataURLtoFile = (dataurl) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([new Blob([u8arr], { type: mime })], 'react-webcam-upload.jpg')
}

const fileUpload = (file, url) => {
  const formData = new FormData()
  formData.append('file', file)
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: formData,
  }).then((res) => res.json())
}
