import React from 'react'
import { countries } from './countries'
import Select from 'rc-select'
import ReactCountryFlag from 'react-country-flag'

import './rc-select.css'

const CountryCodeSelect = ({ value, onChange, setDirty }) => {
  return (
    <Select
      id='countryCode'
      showArrow={false}
      showSearch={true}
      optionLabelProp='label'
      dropdownMatchSelectWidth={288}
      filterOption={(input, option) => {
        return option?.children.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) > -1
      }}
      optionFilterProp='children'
      onChange={onChange}
      onBlur={setDirty}
      value={value}
    >
      {countries.map((v, i) => (
        <Select.Option
          key={i}
          value={v.alpha2code}
          label={
            <div className='flex flex-row items-center'>
              <ReactCountryFlag countryCode={v.alpha2code} svg />
              &nbsp;&nbsp;<span>{v.name}</span>
            </div>
          }
        >
          <div className='flex flex-row items-center'>
            <ReactCountryFlag countryCode={v.alpha2code} svg />
            &nbsp;
            <span>{v.name}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

export default CountryCodeSelect
