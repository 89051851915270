import { PhoneNumberUtil } from 'google-libphonenumber'
const phoneUtil = PhoneNumberUtil.getInstance()

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidPhonenumber = (countryCallingCode, phoneNumber) => {
  if (phoneNumber === undefined) {
    return false
  }
  if (phoneNumber.match(/^[^a-zA-Z]*$/) == null) {
    return false
  }
  try {
    const number = phoneUtil.parseAndKeepRawInput(countryCallingCode + phoneNumber)
    if (phoneUtil.isValidNumber(number)) {
      return true
    }
  } catch (e) {
    return false
  }
  return false
}

export const stringChange = (handler) => (e) => {
  e.preventDefault()
  handler(e.target.value)
}

export const getValidityClass = (val, required = false, regex = /^.+/g) => {
  if (val === undefined) {
    return 'valid'
  }
  // feels like a dirty hack
  if (typeof val === 'function') {
    return val() ? 'valid' : 'invalid'
  } else {
    if (val === '') {
      return required ? 'invalid' : 'valid'
    }
    return val.match(regex) ? 'valid' : 'invalid'
  }
}
