import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getValidityClass, EMAIL_REGEX, isValidPhonenumber, stringChange } from '../common/utils'
import PhoneCodeSelect from '../common/phoneCodeSelect'
import CountryCodeSelect from '../common/countryCodeSelect'
import { countries } from '../common/countries'

const ContactInput = (props) => {
  const { push } = useHistory()

  const [email, setEmail] = useState(props.userProfile?.email)
  // Different countries have the same country code. Phone select returns the phone code with a prefixed 2-chars country code
  const [phoneCodeWithCountryCode, setPhoneCodeWithCountryCode] = useState(props.userProfile?.raw_phoneCode)
  const [mobileNumber, setMobileNumber] = useState(props.userProfile?.raw_mobile)
  const [countryCode, setCountryCode] = useState(props.userProfile?.nationality)
  const onSignup = () => {
    let phoneNumber = phoneCodeWithCountryCode.substring(3, phoneCodeWithCountryCode.length) + '-' + mobileNumber
    props.setBasicProfile({
      raw_phoneCode: phoneCodeWithCountryCode,
      raw_mobile: mobileNumber,
      phoneNumber: phoneNumber,
      email: email,
      nationality: countryCode,
    })
    push('/contact/contactVerification')
  }

  const validateFields = () => {
    return (
      email &&
      email.match(EMAIL_REGEX) &&
      phoneCodeWithCountryCode &&
      isValidPhonenumber(phoneCodeWithCountryCode.substring(2, phoneCodeWithCountryCode.length), mobileNumber) &&
      countryCode &&
      countryCode !== ''
    )
  }

  useEffect(() => {
    if (!props.userProfile) {
      fetch('https://extreme-ip-lookup.com/json') // Get the IP data
        .then((res) => res.json())
        .then((ip) => {
          if (ip.country) {
            const matchedCountry = countries.find((v) => v.alpha2code === ip.countryCode)
            if (matchedCountry) {
              setCountryCode(matchedCountry.alpha2code)
              setPhoneCodeWithCountryCode(matchedCountry.alpha2code + matchedCountry.phoneCode)
            }
          }
        })
        .catch((err) => {})
    }
  }, [])

  return (
    <div className='p-4 xs:p-6 signup'>
      <h2
        className='text-center font-bold'
        onClick={() => {
          // hacky way to reset state
          props.resetState()
          window.location.reload()
        }}
      >
        KYC
      </h2>
      <br />
      <br />
      <label htmlFor='email'>Email *</label>
      <input
        className={getValidityClass(email, true, EMAIL_REGEX)}
        id='email'
        type='email'
        value={email === undefined ? '' : email}
        onChange={stringChange(setEmail)}
        autoFocus
      />
      <div className='w-5/12 inline-block pr-1'>
        <label htmlFor='phoneCode'>Phone code *</label>
        <PhoneCodeSelect value={phoneCodeWithCountryCode} onChange={setPhoneCodeWithCountryCode} />
      </div>
      <div className='w-7/12 inline-block pl-1'>
        <label htmlFor='mobileNumber'>Mobile number *</label>
        <input
          id='mobileNumber'
          className={
            mobileNumber === undefined ||
            isValidPhonenumber(phoneCodeWithCountryCode?.substring(2, phoneCodeWithCountryCode.length), mobileNumber)
              ? 'valid'
              : 'invalid'
          }
          type='text'
          value={mobileNumber === undefined ? '' : mobileNumber}
          onChange={stringChange(setMobileNumber)}
        />
      </div>
      <label htmlFor='countryCode'>Country of citizenship *</label>
      <CountryCodeSelect value={countryCode} onChange={setCountryCode} />
      <br />
      <button className='btn-primary w-full' onClick={onSignup} disabled={!validateFields()}>
        Continue
      </button>
    </div>
  )
}

export default ContactInput
