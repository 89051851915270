import React, { createContext } from 'react'
import ReactDOM from 'react-dom'
import Router from './router'
import { BrowserRouter } from 'react-router-dom'
import { NotificationContext } from './common/notification'
import Notification from 'rc-notification'

// Enable module hot-reloading for Parcel bundler
// (disabled by default)
// if (module.hot) {
//   module.hot.accept()
// }
// Mount the app
let notification = null;
Notification.newInstance({ style: {} }, (n) => (notification = n))

ReactDOM.render(
  <BrowserRouter>
    <NotificationContext.Provider value={notification}>
      <Router />
    </NotificationContext.Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
